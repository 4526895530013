import {get_image_content, importAll} from '../lib/utilities';

const classic_cakes = importAll(require.context('../images/gallery/classic_cakes', false, /\.(webp)$/));
const painted_cakes = importAll(require.context('../images/gallery/painted_cakes', false, /\.(webp)$/));
const custom_cakes = importAll(require.context('../images/gallery/custom_cakes', false, /\.(webp)$/));
const vintage_cakes = importAll(require.context('../images/gallery/vintage_cakes', false, /\.(webp)$/));
const cupcakes = importAll(require.context('../images/gallery/cupcakes', false, /\.(webp)$/));
const painted_macarons = importAll(require.context('../images/gallery/painted_macarons', false, /\.(webp)$/));
const cookies_and_cheesecakes = importAll(require.context('../images/gallery/cookies_and_cheesecakes', false, /\.(webp)$/));

export const gallery_content = [
  {
		title: "Painted Cakes",
		content: {
			type: "grid",
			key: "painted-cakes-content",
      display: {my: 0.1, border: "none", boxShadow: "none"},
      value: Object.entries(painted_cakes).map(get_image_content)
    }
  },
  {
    title: "Painted Macarons",
    content: {
      type: "grid",
      key: "painted-macarons-content",
      display: {my: 0.1, border: "none", boxShadow: "none"},
      value: Object.entries(painted_macarons).map(get_image_content)
    }
  },
  {
		title: "Cupcakes",
		content: {
			type: "grid",
			key: "cupcakes-content",
      display: {my: 0.1, border: "none", boxShadow: "none"},
      value: Object.entries(cupcakes).map(get_image_content)
    }
  },
  {
		title: "Custom Cakes",
		content: {
			type: "grid",
			key: "custom-cakes-content",
      display: {my: 0.1, border: "none", boxShadow: "none"},
      value: Object.entries(custom_cakes).map(get_image_content)
    }
  },
  {
		title: "Vintage Cakes",
		content: {
			type: "grid",
			key: "vintage-cakes-content",
      display: {my: 0.1, border: "none", boxShadow: "none"},
      value: Object.entries(vintage_cakes).map(get_image_content)
    }
  },
  {
		title: "Classic Cakes",
		content: {
			type: "grid",
			key: "classic-cakes-content",
      display: {my: 0.1, border: "none", boxShadow: "none"},
      value: Object.entries(classic_cakes).map(get_image_content)
    }
  },
  {
    title: "Cookies and Cheesecakes",
    content: {
      type: "grid",
      key: "cookies-and-cheesecakes-content",
      display: {my: 0.1, border: "none", boxShadow: "none"},
      value: Object.entries(cookies_and_cheesecakes).map(get_image_content)
    }
  }
];