import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import logo from "../images/logo.webp";

const Header = () => {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpenSubMenu = (event) => setAnchorEl(event.currentTarget);

	const handleCloseSubMenu = () => setAnchorEl(null);

	const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);

	const handleCloseNavMenu = () => setAnchorElNav(null);

	return (
		<AppBar /*position="fixed"*/ style={{ opacity: 1, backgroundColor: "white" }}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex" },
							fontWeight: "bold",
							fontFamily: "Kalnia",
							letterSpacing: ".1rem",
							color: "inherit",
							textDecoration: "none",
						}}
					>
						as you whisk<sup>&trade;</sup>
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							onMouseOver={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
								alignItems: "left"
							}}
						>
							<MenuItem onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/">
									<Typography textAlign="center" fontFamily="Kalnia">Home</Typography>
								</Link>
							</MenuItem>
							<MenuItem onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/aboutus">
									<Typography textAlign="center" fontFamily="Kalnia">About Us</Typography>
								</Link>
							</MenuItem>
							<MenuItem onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery">
									<Typography textAlign="center" fontFamily="Kalnia">Gallery</Typography>
								</Link>
							</MenuItem>
							<MenuItem sx={{backgroundColor:"snow", justifyContent:"flex-end"}} onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery/#classic-cakes">
									<Typography textAlign="center" fontFamily="Kalnia">Classic Cakes</Typography>
								</Link>
							</MenuItem>
							<MenuItem sx={{backgroundColor:"snow", justifyContent:"flex-end"}} onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery/#painted-cakes">
									<Typography textAlign="center" fontFamily="Kalnia">Painted Cakes</Typography>
								</Link>
							</MenuItem>
							<MenuItem sx={{backgroundColor:"snow", justifyContent:"flex-end"}} onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery/#custom-cakes">
									<Typography textAlign="center" fontFamily="Kalnia">Custom Cakes</Typography>
								</Link>
							</MenuItem>
							<MenuItem sx={{backgroundColor:"snow", justifyContent:"flex-end"}} onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery/#vintage-cakes">
									<Typography textAlign="center" fontFamily="Kalnia">Vintage Cakes</Typography>
								</Link>
							</MenuItem>
							<MenuItem sx={{backgroundColor:"snow", justifyContent:"flex-end"}} onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery/#cupcakes">
									<Typography textAlign="center" fontFamily="Kalnia">Cupcakes</Typography>
								</Link>
							</MenuItem>
							<MenuItem sx={{backgroundColor:"snow", justifyContent:"flex-end"}} onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery/#painted-macarons">
									<Typography textAlign="center" fontFamily="Kalnia">Painted Macarons</Typography>
								</Link>
							</MenuItem>
							<MenuItem sx={{backgroundColor:"snow", justifyContent:"flex-end"}} onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/gallery/#cookies-and-cheesecakes">
									<Typography textAlign="center" fontFamily="Kalnia">Cookies and Cheesecakes</Typography>
								</Link>
							</MenuItem>
							<MenuItem onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/menu">
									<Typography textAlign="center" fontFamily="Kalnia">Menu & Pricing</Typography>
								</Link>
							</MenuItem>
							{/*<MenuItem onClick={handleCloseNavMenu}>
								<Link color="secondary" underline="none" href="/christmas_range">
									<Typography textAlign="center" fontFamily="Kalnia">Christmas Range</Typography>
								</Link>
							</MenuItem>*/}
							<MenuItem onClick={handleCloseNavMenu}>
								<Link
									color="secondary"
									underline="none"
									href="/#contact-details-grid"
								>
									<Typography textAlign="center" fontFamily="Kalnia">Contact Us</Typography>
								</Link>
							</MenuItem>
						</Menu>
					</Box>
					<Typography
						variant="h5"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: "flex", md: "none" },
							flexGrow: 1,
							fontWeight: "bold",
							fontFamily: "Kalnia",
							color: "inherit",
							letterSpacing: ".1rem",
							textDecoration: "none",
						}}
					>
						as you whisk<sup>&trade;</sup>
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
						<Button
							onClick={handleCloseNavMenu}
							sx={{ my: 2, color: "white", display: "block" }}
						>
							<Link color="secondary" underline="none" href="/" fontFamily="Kalnia">
								Home
							</Link>
						</Button>
						<Button
							onClick={handleCloseNavMenu}
							sx={{ my: 2, color: "white", display: "block" }}
						>
							<Link color="secondary" underline="none" href="/aboutus" fontFamily="Kalnia">
								About Us
							</Link>
						</Button>
						<Button
							aria-owns={anchorEl ? "sub-menu" : undefined}
							aria-haspopup="true"
							onMouseOver={handleOpenSubMenu}
							color="inherit"
							onClick={() => {handleCloseNavMenu(); handleCloseSubMenu();}}
							sx={{ my: 2, color: "white", display: "block" }}
						>
							<Link 
								color="secondary" underline="none" href="/gallery" fontFamily="Kalnia">
								Gallery
							</Link>
						</Button>
						<Button
							onClick={handleCloseNavMenu}
							sx={{ my: 2, color: "white", display: "block" }}
						>
							<Link color="secondary" underline="none" href="/menu" fontFamily="Kalnia">
								Menu & Pricing
							</Link>
						</Button>
						{/*<Button
							onClick={handleCloseNavMenu}
							sx={{ my: 2, color: "white", display: "block" }}
						>
							<Link color="secondary" underline="none" href="/christmas_range" fontFamily="Kalnia">
								Christmas Range
							</Link>
						</Button>*/}
						<Button
							onClick={handleCloseNavMenu}
							sx={{ my: 2, color: "white", display: "block" }}
						>
							<Link
								color="secondary"
								underline="none"
								href="/#contact-details-grid"
								fontFamily="Kalnia"
							>
								Contact Us
							</Link>
						</Button>
					</Box>
					<Menu
						id="sub-menu"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						open={Boolean(anchorEl)}
						onClose={handleCloseSubMenu}
						MenuListProps={{ onMouseLeave: handleCloseSubMenu }}
						sx={{
							display: { xs: "none", sm: "block", md: "block" },
							alignItems: "left",
						}}
					>
						<MenuItem onClick={handleCloseSubMenu}>
							<Link color="secondary" underline="none" href="/gallery/#classic-cakes">
								<Typography textAlign="center" fontFamily="Kalnia">Classic Cakes</Typography>
							</Link>
						</MenuItem>
						<MenuItem onClick={handleCloseSubMenu}>
							<Link color="secondary" underline="none" href="/gallery/#painted-cakes">
								<Typography textAlign="center" fontFamily="Kalnia">Painted Cakes</Typography>
							</Link>
						</MenuItem>
						<MenuItem onClick={handleCloseSubMenu}>
							<Link color="secondary" underline="none" href="/gallery/#custom-cakes">
								<Typography textAlign="center" fontFamily="Kalnia">Custom Cakes</Typography>
							</Link>
						</MenuItem>
						<MenuItem onClick={handleCloseSubMenu}>
							<Link color="secondary" underline="none" href="/gallery/#vintage-cakes">
								<Typography textAlign="center" fontFamily="Kalnia">Vintage Cakes</Typography>
							</Link>
						</MenuItem>
						<MenuItem onClick={handleCloseSubMenu}>
							<Link color="secondary" underline="none" href="/gallery/#cupcakes">
								<Typography textAlign="center" fontFamily="Kalnia">Cupcakes</Typography>
							</Link>
						</MenuItem>
						<MenuItem onClick={handleCloseSubMenu}>
							<Link color="secondary" underline="none" href="/gallery/#painted-macarons">
								<Typography textAlign="center" fontFamily="Kalnia">Painted Macarons</Typography>
							</Link>
						</MenuItem>
						<MenuItem onClick={handleCloseSubMenu}>
							<Link color="secondary" underline="none" href="/gallery/#cookies-and-cheesecakes">
								<Typography textAlign="center" fontFamily="Kalnia">Cookies and Cheesecakes</Typography>
							</Link>
						</MenuItem>
					</Menu>
					<Link href="/">
						<img
							alt="as you whisk Logo"
							width="64px"
							height="64px"
							src={logo}
							style={{ marginRight: 1, display: { xs: "none", md: "flex" } }}
						/>
					</Link>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default Header;
