import logo from "../images/logo.webp";
import koi_pond from "../images/gallery/custom_cakes/koi_fish_pond.webp";
import blue_velvet from "../images/gallery/classic_cakes/blue_velvet_cake.webp";
import chocolate_velvet from "../images/gallery/cupcakes/chocolate_velvet.webp";
import oscar_cake from "../images/gallery/painted_cakes/oscar_cake.webp";
import cookies_assorted from "../images/gallery/cookies_and_cheesecakes/assorted_cookies.webp";
import krav_sheet_cake from "../images/gallery/vintage_cakes/krav_sheet_cake.webp";
import multi_colour_cupcakes from "../images/gallery/cupcakes/multi_colour_cupcakes.webp";
import custom_themed_cupcakes from "../images/gallery/cupcakes/custom_themed_cupcakes.webp";
import geelong_cats_themed_cupcakes from "../images/gallery/cupcakes/fathers_day_geelong_cats_cupcakes.webp";
import fathers_day_cupcakes from "../images/gallery/cupcakes/fathers_day_cupcakes.webp";
import christmas_range_image from "../images/christmas_range/painted_macarons.webp";
import party_favour_cupcakes from "../images/gallery/cupcakes/party_favours.webp";
import weird_fruit_cupcakes from "../images/gallery/cupcakes/weird_fruit_cupcakes.webp";
import { sendEmail } from "../lib/utilities";

const promotions = [
	{
		name: "Standard Menu & Pricing",
		event: "Custom Painted Cakes, Cupcakes, Cookies and Macarons",
		src: christmas_range_image,
		statement: [
			{
				type: "string",
				key: "menu-intro",
				value: "Peruse our standard menu of treats and delights with our signature sweet adjustable buttercream. Pick your sweet level from our low, medium and high options and let our artist paint your dreams & wishes onto your custom cakes.",
				display: { display: "inline-block", align: "justify" },
			},
			{
				type: "link",
				key: "menu-link",
				display: { align: "center", justify: "center", display: "flex", weight: 'bold', backgroundColor: "#00000005", underline: true },
				value: {
					text: "Menu",
					href: "/menu"
				},
			}
		],
		active: false
	}
];

const testimonials = [
	{
		name: "Sarah W",
		event: "Mother's 60 Birthday - Very Vanilla Cake - Painted Topper (~60 people)",
		src: oscar_cake,
		statement: "“The cake was so beautiful and exceeded my expectations. It got wow responses from other guests and also comments about how good it tasted too!”"
	},
	{
		name: "Jen",
		event: "STEMpals Work Event",
		src: weird_fruit_cupcakes,
		statement: "Having tried As you Whisk's standard flavours, I knew I was in for a treat when i asked for a cake that sparks curiosity for a work event that was all about curiosity and perceptions.🌱As you whisk delivered on sparking curiosity with their `fruit` cupcakes that were delicious. Jayashree brought together her science background with her pastry chef to make these delicious cupcakes. That weren't made with what we typical think of as fruit, instead using the botanical definition of a fruit as a seed bearing structure formed from the ovary of a flowering plant. 🍅🌶 These cupcakes are capsicum and chilli cake with a tomato jam filling, and an aquafaba butter cream. And vegan so covering off all our dietary requirements. The vegan buttercream, which tastes as luscious and light as it looks. Was a pièce de résistance,   absolutely masterly of vegan butter cream."
	},
	{
		name: "Vivek",
		event: "Daugther's Birthday - Custom Themed Cupcakes",
		src: custom_themed_cupcakes,
		statement: "The cupcakes from `as you whisk` were a huge hit at my daughter’s 13th birthday party! The flavours were delicious and the custom decorations were adorable. They went to the extra trouble in getting the customisations just right. The owner's were so easy to work with and the prices were very reasonable. We’ll definitely be ordering again for future events",
	},
	{
		name: "Krav Maga Australia",
		event: "Business 5 Year Celebration - Red Velvet Sheet Cake - Painted Logo and Macaron Toppers (~60 people)",
		src: krav_sheet_cake,
		statement: "“EVERYTHING was absolutely amazing. The AsYouWhisk team were a delight to deal with and very prompt and professional. The cake itself was simply sensational. Absolutely delicious. The only thing more impressive than the taste was the design/artwork. The AsYouWhisk team completely nailed the brief and we received excessive comments/feedback on the impressiveness of the design and decor. Thank you so much and we will definitely be using you again!”"
	},
	{
		name: "Andrew",
		event: "Fathers Day - Geelong Cats Themed Cupcakes",
		src: geelong_cats_themed_cupcakes,
		statement: "I ordered the Father’s Day Special Box, and it was an absolute winner! Each cupcake was decked out in a magnificent Geelong Cats theme, and they were a big hit with my family. The flavors were rich, and the icing was smooth and creamy. Shyamal and Jayashree put a lot of love and care into their baking, and you can taste it in every bite! Highly recommend!"
	},
	{
		name: "Sarah W",
		event: "Grandmother's 86th Birthday - Koi Pond Cake (~15 people)",
		src: koi_pond,
		statement: "Will Abso-blooming-lutely recommend `as you whisk` to friends and family!"
	},
	{
		name: "Prem V",
		event: "First Birthday - Royal Blue Velvet Tiered Cake (~60 people)",
		src: blue_velvet,
		statement: "“The cake was amazing looks wise and tasted as good as it looked. The multiple shades of blue coloured layers of cake and the incredible 2 tiers with gold drip was just magnificent. Well done and thank you for making our party more colourful.”"
	},
	{
		name: "Diva",
		event: "21st Birthday - Chocolate Velvet Cupcakes  (~15 people)",
		src: chocolate_velvet,
		statement: "“One of the best cupcakes I’ve ever had!! Super tasty and so moist it was just perfect!!”"
	},
	{
		name: "Scott J",
		event: "Work Morning Tea - Snickerdoodle and Chocolate Chip Cookies (Dozen each)",
		src: cookies_assorted,
		statement: "“Lots of positive comments around the presentation and taste! Will certainly be using As You Whisk again!”"
	},
	{
		name: "Jennifer",
		event: "Birthday - Multi-coloured Cupcakes",
		src: multi_colour_cupcakes,
		statement: "I liked that I was invited with open arms to customise even the smallest details of the cupcakes design including the shades of colour, buttercream icing design and sweetness level. Jayashree made me feel comfortable to talk about exactly what design I wanted/expected and was proactive in making sure I was satisfied. The cupcakes were delicious and Shyamal’s artistic work was almost too pretty to eat. The whole design, payment and delivery experience was smooth. I will definitely be a returning customer.",
	},
	{
		name: "Simone",
		event: "Fathers Day - Cupcakes and Cookies",
		src: fathers_day_cupcakes,
		statement: "Requested a late notice order from overseas for my dad for Father’s Day. Jayashree & Shyamal were so beautiful to deal with! They gave me so many different options & even made the cookies soft so my Dad could manage them. They delivered them with so much care and my Dad was stoked! The gift box was lovely & my Dad said the cookies & cupcakes were both delicious! He sent me a photo & I was so impressed. These guys will definitely be my go to from now on. Thank you so much for making my dad’s day & being so accomodating. 🙏💜"
	},
	{
		name: "Juliet",
		event: "Ladies Dinner - Party Favour Cupcakes",
		src: party_favour_cupcakes,
		statement: "We ordered 100+ cupcakes for an event. They were so beautifully decorated with attention to detail. Shyamal and Jayashree were able to make 3 flavours with differing icing designs and package each cupcake individually with ingredient list on the bottom. They gave us options to keep within our budget. Delivery for the event was smooth and they looked so lovely on our table setting. Everybody loved their pretty cupcakes! Thank you!",
	}
];
export const home_content = 
	(promotions.filter(p => p.active).length ? [{
		title: "",
		content: {
			type: "carousel",
			display: { align: "center", duration: 1000, interval: 7000, mx: {xs: "5vw"}, height: {xs: "120vh", md: "inherit"}, mt: 10},
			key: "promotions",
			value: [
				...promotions.filter(p => p.active).map((promotion, index) => ({
					type: "card",
					key: `promotion-${index}`,
					display: {
						flexDirection: "row",
						md: 2,
						style: { border: "none", boxShadow: "none" },
						align: "center",
						backgroundColor: "#FFC00080",//"#b59410BF",
						title: {font: "Averia Serif Libre", component: "h5", variant: "h5"},
						subtitle: {font: "Averia Serif Libre", color: "maroon"}
					},
					value: {
						src: promotion.src,
						alt: `Image for promotion of ${promotion.name} for a ${promotion.event}`,
						title: promotion.name,
						subtitle: promotion.event,
						display: { align: "center", font: "Permanent Marker"},
						content: {
							type: "string",
							key: `statement-${index}`,
							display: { align: "justify"},
							value: promotion.statement
						}
					}
				}))
			]
		}
	}] : []).concat([
	{
		title: "",
		content: {
			type: "carousel",
			display: { align: "center", mt: 3, height: {xs: "150vh", md: "inherit"}, duration: 1000, interval: 10000},
			key: "carousel",
			value: testimonials.map((testimonial, index) => ({
				type: "card",
				key: `testimonial-${index}`,
				display: {
					flexDirection: "row",
					md: 6,
					style: { border: "none", boxShadow: "none" },
					align: "center"
				},
				value: {
					src: testimonial.src,
					alt: `Image for testimonial of ${testimonial.name} for a ${testimonial.event}`,
					title: testimonial.name,
					subtitle: testimonial.event,
					display: { align: "center"},
					content: {
						type: "string",
						key: "statement-index",
						display: { align: "center"},
						value: testimonial.statement
					}
				}
			}))
		}
	},
	{
		title: "",
		content: {
			type: "card",
			key: "banner-card",
			display: {
				flexDirection: "row",
				md: 6,
				mt: 5,
				style: { border: "none", boxShadow: "none" },
			},
			value: {
				src: logo,
				alt: "as you whisk logo in the banner",
				title: "as you whisk",
				subtitle: "Tastes as good as it looks",
				content: {
					type: "list",
					key: "banner-text",
					value: [
						{
							type: "string",
							key: "intro",
							value:
								"We are a council registered small home-based business in the South-Eastern suburbs of Melbourne, Australia. We are a team of two - a patisserie chef and an artist. We hope to work with you to create personalised cakes and cupcakes for your events. Our cakes are customisable, not just in design, but also in sweetness levels. We make cakes with heart and soul in them. Sure, we can probably make you that gorgeous cake you have on your pinterest, if you wish. But we can also create one uniquely for you. We'd love to!",
							display: { align: "justify" },
						},
						{
							type: "string",
							key: "story",
							value: [
								{
									type: "string",
									key: "story-brand",
									value: "as you whisk",
									display: {
										font: "kalnia",
										align: "inherit",
										display: "inline",
									},
								},
								{
									type: "string",
									key: "story-text",
									value:
										" was conceived because we were unhappy with the beautiful cakes we were running into at events and in shops. They usually look gorgeous and make you want to eat them, but when you do you end up biting into sugary sweet American buttercream, fondant, modelling chocolate or sugar paste. Before you know it, you’re sweeted out and cannot have anymore. Make no mistake, we love our sweets and believe dessert should be a separate meal altogether. However, we want our cakes to highlight the flavours we put into it and not just the plain boring taste of refined white sugar. So we started experimenting on making our ideal cakes ourselves. And lo and behold, we ran into problems. We learnt all the reasons things like American buttercream, fondant, modelling chocolate, and sugar paste are convenient and create such beautiful cakes. We learnt the kind of looks we can create while avoiding these and the kind of looks we cannot create. After months of experimenting and creating, we bring to you our portfolio.",
									display: { align: "inherit", display: "inline" },
								},
							],
							display: { align: "justify" },
						},
					],
				},
			},
		},
	},
	{
		content: {
			type: "grid",
			key: "contact-details-grid",
			display: { align: "center", xs: 12, sm: 6, md: 4, boxShadow: "none", border:"none" },
			value: [
				{
					title: "Contacts",
					content: {
						type: "list",
						display: { align: "center"},
						key: "contact-details-list",
						value: [
							{
								type: "link",
								key: "contact-email",
								display: { align: "center", justify: "center", display: "flex" },
								value: {
									icon: "email",
									text: "humans@asyouwhisk.com.au",
									href: "mailto:humans@asyouwhisk.com.au",
								},
							},
							{
								type: "link",
								key: "contact-number",
								display: { align: "center", justify: "center", display: "flex" },
								value: {
									icon: "phone",
									text: "+61 422 163 803",
									href: "tel:+61422163803",
								},
							},
							{
								type: "link",
								key: "contact-instagram",
								display: { align: "center", justify: "center", display: "flex" },
								value: {
									icon: "insta",
									text: "@as.you.whisk",
									href: "https://www.instagram.com/as.you.whisk/",
									target: "_blank",
								},
							},
						],
					},
				},
				{
					title: "Enquiry Form",
					content: {
						type: "form",
						key: "enquiry-form",
						handleSubmit: sendEmail, 
						value: [
							{name: "name", type: "name", label: "Full Name", placeholder: "Enter Full Name", error: "Name should be at least 2 characters and have alphabets or spaces."},
							{name: "mobile", type: "mobile", label: "Mobile Number", placeholder: "Enter Australian mobile number", error: "Mobile number must be Australian and start with +614 or 04 followed by 8 digits."},
							{name: "email", type: "email", label: "Email", placeholder: "Enter email", error: "Invalid email format detected. Please provide valid email", ariaLabel: 'email'},
							{name: "message", type: "message", label: "Enquiry", placeholder: "Enter order details with expected delivery/pickup date", error: "Enquiry must be at least 10 characters long.", multiline: true, rows: 4}
						] 
					}
				}
			],
		},
	},
]);
